import React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'grommet';
import { Hero, Ram } from '../../ui';
import Layout from '../../layout/primary';
import htmlSerializer from '../../prismic/htmlSerializer';

export const query = graphql`
  {
    prismic {
      content: allCareerss {
        edges {
          node {
            careers_search_title
            careers_search_body
            careers_search_hero_image
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  return (
    <Layout title="Careers Search">
      <Hero
        imageMobile={
          content.careers_search_hero_image &&
          content.careers_search_hero_image.mobile &&
          content.careers_search_hero_image.mobile.url
        }
        imageDesk={content.careers_search_hero_image && content.careers_search_hero_image.url}
        heroHeader={content.careers_search_title[0].text}
        heroCopy={content.careers_search_body[0].text}
        htmlSerializer={htmlSerializer}
      />
      <Box pad={{ horizontal: 'xlarge', top: 'xlarge', bottom: '0' }}>
        <Box
          align="center"
          direction="row-responsive"
          gap="medium"
          justify="center"
          margin="auto"
          width="1440px"
        >
          <iframe
            title="Careers Search"
            src="https://chu.tbe.taleo.net/chu04/ats/careers/jobSearch.jsp?act=redirectCws&cws=1&org=POWERENGINEERS"
            width="100%"
            height="1600px"
            style={{ border: 0 }}
            id="iframe"
          />
        </Box>
      </Box>
      <Ram color="CB361D" />
    </Layout>
  );
}
